import { Injectable } from '@angular/core';
import { ResetPassRequestDTO } from 'src/app/DTO/ResetPassRequestDTO';
import { ApiService } from 'src/app/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class PasswordResetService {

  constructor(
    private api: ApiService
  ) { }

  checkToken(userid, token) {
    return new Promise<any>(async (resolve, reject) => {
      this.api.checkResetPassword(userid, token)
        .subscribe((result:any) => {
          try {
            resolve(result);
          }
          catch (err) {
            console.warn('Error on check token', err);
            reject(result);
          }
        })
    })    
  }

  sendNewPassword(model: ResetPassRequestDTO) {
    console.log(model);
    return new Promise<any>(async (resolve, reject) => {
      this.api.resetPassword(model)
        .subscribe((result: any) => {
          try {
            resolve(result);
          }
          catch (err) {
            console.warn('Error on reset pas', err);
            reject(result);
          }
        })
    })
  }
}
