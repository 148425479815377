
import { AfterViewInit, Component, type OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ResetPassRequestDTO } from 'src/app/DTO/ResetPassRequestDTO';
import { ApiService } from 'src/app/services/api.service';
import { PasswordResetService } from './services/password-reset.service';
import { CommunityDTO } from 'src/app/DTO/CommunityDTO';
import { ThemeService } from 'src/app/services/theme/theme.service';
import Swal from 'sweetalert2';
import { ResultMessageType } from '../error/error.component';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent implements OnInit, AfterViewInit {

  form: FormGroup;
  submitAttempt = false;

  recoveryModel: ResetPassRequestDTO = new ResetPassRequestDTO();

  community: CommunityDTO;
  communityName: string;
  isCustomCommunity: boolean;
  userId: number;
  token: string;

  customLogo: string;

  isWeCoo: boolean;

  isLoading = true;

  public appName = "Wecoo App";
  public bgImage = "https://storage.googleapis.com/wetown/imgs/Common/corp/bg/bg-gradient.png";
  public logoImage = "https://storage.googleapis.com/wetown/imgs/Common/corp/logos/wecoo/vertical-white.svg";
  public buttonColor = "#ffffff";
  public buttonBg = "#8779FF";

  cssVariables = null;
  

  constructor(private api: ApiService,
              private activeRoute: ActivatedRoute,
              private translate: TranslateService,
              private passwordResetService: PasswordResetService,
              private router: Router,              ) {}  

  ngOnInit(): void {
    this.communityName = this.activeRoute.snapshot.params['community'];
    this.userId = parseInt(this.activeRoute.snapshot.params['userId']);
    this.token = this.activeRoute.snapshot.params['token'];       

    this.form = new FormGroup({
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
      confirmPassword: new FormControl('', [Validators.required, Validators.minLength(6)])
    })

    this.recoveryModel = {
      User: this.userId,
      Token: this.token,
      NewPass: ''
    };
  }

  ngAfterViewInit(): void {
    this.passwordResetService.checkToken(this.userId, this.token)
    .then((response) => {      
      console.log(response);
      if (!response) {        
        const title = this.translate.instant('Oops');
        const message = this.translate.instant('PASSWORD_RESET.TokenOrLinkBad');

        this.router.navigate([`/error`], { state: { title, message } });
      }
      else {          
        this.configPage();
      }
    })  
  }

  public configPage() {
    this.api.getCommunityByCode(this.communityName)
      .subscribe((community) => {
        console.log(community);
        this.community = community;
        this.isWeCoo = community.communityType != 0
        this.isCustomCommunity = community.customAppId != null;
        
        if (this.isCustomCommunity) {          
          this.cssVariables = ThemeService.getThemeCssVariablesByCommunity(this.community);          
          this.customLogo = community.logoNegative ?? community.image ;          
        } else if (!this.isWeCoo) {
          this.bgImage = "https://storage.googleapis.com/wetown/imgs/Common/corp/bg/bg-gradient-wetown.png";
          this.logoImage = "https://storage.googleapis.com/wetown/imgs/Common/corp/logos/wetown/vertical-white.svg";
          this.buttonColor = "#8400cf";
          this.buttonBg = "#ffffff";
        }    
        
        this.isLoading = false;
      })
  }

  public sendResetPassword() {
    this.submitAttempt = true;

    const pass1 = this.form.controls['password'].value;
    const pass2 = this.form.controls['confirmPassword'].value;

    const samePass = pass1 === pass2;

    if(this.form.valid) {      
      if(samePass) {
        this.recoveryModel.NewPass = pass1;        
        this.passwordResetService.sendNewPassword(this.recoveryModel)
          .then((result) => {
            if(result.success) {
              const title = this.translate.instant('PASSWORD_RESET.PasswordChanged');
              const message = this.translate.instant('PASSWORD_RESET.YouCanCloseWindow');
              this.router.navigate([`/success`], { state: { title, message, type: ResultMessageType.Success }})              
            }
          })
          .catch((result) => {
            console.log(result);
            Swal.fire({
              title: this.translate.instant('Oops'),
              text: this.translate.instant('PASSWORD_RESET.ErrorOnUpdate'),
              icon: 'error'
            })
          });        
      }
      else {
        Swal.fire({
          title: this.translate.instant('Oops'),
          text: this.translate.instant('PASSWORD_RESET.PassNotSame'),        
          icon: 'warning'  
        })
      }      
    }

    this.submitAttempt = false;    
  }
}
