<div class="inscription-card">
  <div class="image-box">
    <ng-container >
      <img [src]="inscription.images[0]?.path ?? defaultImg | imageUrl" >
    </ng-container>
    <div class="category" *ngIf="inscription.categoryName">
      <span>{{ inscription.categoryName }}</span>
    </div>
  </div>
  <div class="info-box">
    <div class="event-title">
      <span>{{ inscription.name }}</span>
    </div>
    <div class="data-box" *ngIf="false">
      <div class="when">
        <span>
            <i class="calendar-icon"></i> {{ dateFrom | localizedDate: "dd/MM/yyyy HH:mm" }} h
        </span>
      </div>
    </div>
  </div>
</div>
