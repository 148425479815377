<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.15.3/css/all.css">

<div class="wrapper">

    <i *ngIf="type === MessageType.Error" class="far fa-times-circle color-red error-circle"></i>
    <i *ngIf="type === MessageType.Success" class="far fa-check-circle color-green success-circle"></i>

    <h1>{{ title }}</h1>
    <h2>{{ message }}</h2>

</div>