
<input #fileUpload type="file" class="file-input"
[accept]="requiredFileType"
(change)="onFileSelected($event)"
[multiple]="maxFiles > 1">

<!--
<div class="file-upload mb-3">
    <button *ngIf="!thumbnail" type="button" mat-mini-fab color="primary" class="upload-btn me-2" (click)="fileUpload.click()">
        <i class="fa fa-image"></i>
    </button>

    <span *ngIf="fileName != null">{{fileName}}</span>
    <span *ngIf="fileName == null">No s'ha carregat cap imatge</span>
</div>
-->


<!--
<div class="progress-wrapper">
    <mat-progress-bar class="progress-bar" mode="determinate" [value]="uploadProgress" *ngIf="uploadProgress">
    </mat-progress-bar>

    <i class="fa fa-times cancel-upload" (click)="cancelUpload()" *ngIf="uploadProgress"></i>
</div>
-->

<div class="thumbs-wrapper">
    <div *ngFor="let file of files" class="thumb" [class.error]="file.error"
        (drop)="dropHandler($event, file)"
        (dragenter)="dragEnterHandler($event, file)"
        (dragover)="dragOverHandler($event, file)"
        (dragleave)="dragLeaveHandler($event, file)"
        [class.is-dragover]="file.isDragover"
        >
        <ng-container *ngIf="type == Type.Image">
            <img *ngIf="file.path" [attr.data-fancybox]="'gallery-' + componentUuid" [src]="file.path | imageUrl" alt="Imagen" [ngStyle]="{'object-fit': fit}">
        </ng-container>

        <ng-container *ngIf="type == Type.File">
            <div *ngIf="file.path" class="flex-center">
                <div>
                    <i class="fa fa-file"></i>
                </div>
                <div class="filename">
                    {{file.originalName}}
                </div>
            </div>
        </ng-container>

        <div *ngIf="file.path" class="overlay" (click)="show($event, file)">
            <i class="fa fa-eye"></i>
            <div class="remove-button" *ngIf="!viewOnly" (click)="removeConfirm($event, file)">
                <i class="fa fa-trash"></i>
            </div>
        </div>

        <div *ngIf="file.error" class="overlay" (click)="remove(file)">
            <i class="fa fa-trash"></i>
        </div>

        <div *ngIf="file.uploadProgress != null" class="flex-center">
            <div class="circular-progress">
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' role='graphic'>
                    <circle cx="50" cy="50" r="40" ></circle>
                    <circle cx="50" cy="50" r="40" id='pct-ind' [style.strokeDashoffset]="file.dashoffset"></circle>
                </svg>
                <!--<p class="pct">{{uploadProgress}}%</p>-->
            </div>
        </div>

        <div *ngIf="file.error" class="flex-center">
            <i class="fa fa-exclamation"></i>
        </div>
    </div>

    <div *ngIf="!files || files.length < maxFiles && !viewOnly" id="drop_zone" (click)="onClick()" (drop)="dropHandler($event)"
        (dragenter)="dragEnterHandler($event)" (dragover)="dragOverHandler($event)" (dragleave)="dragLeaveHandler($event)"
        [class.is-dragover]="dropZone_IsDragOver">
        <ng-container>
            <ng-container *ngIf="type == Type.Image">
                <span *ngIf="maxFiles == 1">{{ 'FILE_UPLOADER.DragImageHere' | translate }}</span>
                <span *ngIf="maxFiles > 1">{{ 'FILE_UPLOADER.DragImagesHere' | translate }}</span>
            </ng-container>
            <ng-container *ngIf="type == Type.File">
                <span *ngIf="maxFiles == 1">{{ 'FILE_UPLOADER.DragFileHere' | translate }}</span>
                <span *ngIf="maxFiles > 1">{{ 'FILE_UPLOADER.DragFilesHere' | translate }}</span>
            </ng-container>


            <div>
                <i class="fa fa-plus"></i>
            </div>

            {{ 'FILE_UPLOADER.OrClickHere' | translate }}
        </ng-container>
    </div>

</div>


