import { Pipe, PipeTransform } from '@angular/core';
import { BUCKET_NAME } from 'src/environments/environment';

@Pipe({
  name: 'imageUrl'
})
export class ImageUrlPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): string {
    if(!value){
      return '';
    }
    return "https://storage.googleapis.com/" + BUCKET_NAME + "/imgs" + value;
  }

}
