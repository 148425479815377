<div *ngIf="isLoading" class="loading-screen">
    <app-spinner></app-spinner>
</div>

<img class="globe g1" src="assets/share-link/globe-1.png"/>
<img *ngIf="communityCode != 'begur'" class="globe g2" src="assets/share-link/globe-2.png"/>
<img *ngIf="communityCode == 'begur'" class="globe g2" src="assets/share-link/begur/globe-2.png"/>

<img *ngIf="isWeTown" class="logo" src="https://storage.googleapis.com/wetown/imgs/Common/corp/logos/wetown/vertical-white.svg"/>
<img *ngIf="!isWeTown" class="logo" src="https://storage.googleapis.com/wetown/imgs/Common/corp/logos/wecoo/vertical-white.svg"/>

<div *ngIf="!isLoading" class="container position-relative">
    <div class="row">
        <div class="col-lg-6 padding-top">

            <section *ngIf="!hasContent" class="app-icon">
                <img *ngIf="appIcon" class="app-icon" [src]="appIcon" />
            </section>

            <section *ngIf="hasContent || appScreenshot" class="device-wrapper">
                <div class="device device-iphone-14">
                    <div class="device-frame">

                        <div *ngIf="!hasContent && appScreenshot" class="screenshot">
                            <img [src]="appScreenshot">
                        </div>

                        <div *ngIf="hasContent" class="app" [style.--corp-color-gradient-from]="gradientFrom" [style.--corp-color-gradient-to]="gradientTo">
                            <div class="banner">
                                <div><fa-icon [icon]="faBars"></fa-icon></div>
                                <div class="app-title">{{appName}}</div>
                                <div><fa-icon [icon]="faBell"></fa-icon></div>
                            </div>

                            <div class="card">

                                <h1>{{og_title}}</h1>
                                
                                <img [src]="og_image"/>
                                
                                <div class="body">
                                    {{og_description}}
                                </div>

                                <img class="faces" src="assets/share-link/faces.png">
                            </div>
                        </div>
                    </div>
                    <div class="device-stripe"></div>
                    <div class="device-header"></div>
                    <div class="device-sensors"></div>
                    <div class="device-btns"></div>
                    <div class="device-power"></div>
                    <div class="device-home"></div>
                </div>
            </section>
        </div>

        <div class="col-lg-6">
            <section class="info">
                <div class="title">
                    <h1>{{appName}}</h1>
                    <h2 [innerHTML]="'LINK.Text' | translate:{ appName }"></h2>
                </div>
    
                <div class="row links text-center">
                    <div class="col">
                        <a *ngIf="urlAndroid" [href]="urlAndroid" target="_blank" (click)="redirectToAndroid($event)">
                            <img src="assets/share-link/badge_google_play.png"/>
                        </a>
                    </div>
                    <div class="col">
                        <a *ngIf="urlIos" [href]="urlIos" target="_blank" (click)="redirectToIOS($event)">
                            <img src="assets/share-link/badge_app_store.png"/>
                            <!--div class="soon">Pròximament</div-->
                        </a>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>

<!-- Facebook Pixel Code -->
<script>
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window,document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
     fbq('init', '292685268894894'); 
    fbq('track', 'PageView');
</script>
<noscript>
    <img height="1" width="1" src="https://www.facebook.com/tr?id=292685268894894&ev=PageView&noscript=1"/>
</noscript>
<!-- End Facebook Pixel Code -->