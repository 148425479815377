import { Component, ElementRef, EventEmitter, ViewChild, type OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TicketDTO } from 'src/app/DTO/TicketDTO';
import { CommunityService } from 'src/app/services/community/community.service';
import { SwiperContainer } from 'swiper/element';
import { TicketsService } from './services/tickets.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PUBKEY } from 'src/environments/environment';
import {CustomFieldDTO} from "../../../../DTO/CustomFieldDTO";

@Component({
  selector: 'app-payment-modal',
  templateUrl: './payment-modal.component.html',
  styleUrls: ['./payment-modal.component.scss'],
})
export class PaymentModalComponent implements OnInit {

  @ViewChild('swiper') private swiperRef: any;

  public communityId: number;
  public communityCode: string;
  public corpColor: string;
  public ticket: TicketDTO;
  public quantity: number;
  public totalPrice: number;
  public dateFrom: DateTime;
  public dateTo: DateTime;
  public fullFree: boolean;
  public onlyFree: boolean;
  public availableTickets: any;

  public canContinute: boolean;
  public formIsValid: boolean;

  public currentSlide: number = 0;
  public isPurchaseLoading = false;
  public clientSecret: string;
  public lang: string;
  public paymentLoaded = true;
  public paymentStep = false;
  public canPay: boolean;
  public stripe: any;
  public stripeElements: any;
  public paymentStarted: boolean = false;
  public isLoading = true;
  public swiperLoaded = false;
  public isFreePayment = false;
  public ephemeralKey: string;
  public isCustom: boolean;
  public customFieldsForm : CustomFieldDTO[];
  private swiper: SwiperContainer;
  private userData = {};
  private ticketPurchaseUuid: string;
  private customCommunities = [
    'tortola-de-henares'
  ]

  public submitAttempt = new EventEmitter();

  constructor(private dialogConfig: DynamicDialogConfig,
              private communityService: CommunityService,
              private ticketsService: TicketsService,
              private translate: TranslateService,
              private languageService: LanguageService,
              private ref: DynamicDialogRef,
              private el: ElementRef) { }

  ngOnInit(): void {

    this.ref.onClose.subscribe(() => {
      this.totalPrice = 0;

    })

    this.ticket = this.dialogConfig.data.ticket;
    this.fullFree = this.ticket.prices.every(x => x.totalWithFee == 0);
    this.dateFrom = DateTime.fromJSDate(new Date(this.ticket.saleDateFrom));
    this.dateTo = DateTime.fromJSDate(new Date(this.ticket.saleDateTo));
    this.customFieldsForm = this.ticket.customFields.filter(x=> x.publicPermissions == 'rw');
    this.init();
  }

  public slideChange(event) {
    this.currentSlide = event?.detail?.[0]?.activeIndex;
    this.paymentStep = this.currentSlide == 2;
  }

  public changeStep(event, type) {
    switch(type) {
      case 'ticket':
        // .totalQty
        this.totalPrice = event.total;
        this.quantity = event.totalQty;
        this.canContinute = this.quantity > 0;
        break;
      case 'user':
        this.userData = event['user-data'];
        this.canContinute = true;
        if(this.userData && this.totalPrice != 0) {
          this.paymentLoaded = false;
        }
        break;
      case 'payment':
        this.canContinute = true;
        break;
    }
  }

  public next() {
    if (this.canContinute) {
      if (this.currentSlide > 0){

        this.submitAttempt.emit();

        if (!this.formIsValid){
          Swal.fire({
            position: "top" ,
            title: this.translate.instant('GENERIC.bad'),
            icon: 'warning',
            text: this.translate.instant('CheckFormFields'),
            confirmButtonText: this.translate.instant('Continue')
          });
          return;
        }

        if (this.currentSlide === 1) {
          this.purchase();
        } else if (this.currentSlide === 2) {
          this.checkoutPayment();
        }
      }

      this.swiper.swiper.slideNext();
      this.canContinute = false;
    }
  }

  public prev() {
    this.swiper.swiper.slidePrev();
    this.canContinute = true;
  }

  private checkoutPayment() {
    this.paymentStarted = true;
    try {
      this.stripe.confirmPayment({
          elements: this.stripeElements,
          redirect: 'if_required',
          confirmParams: {
            return_url: '',
            payment_method_data: {
              billing_details: {
                address: {
                  country: 'es'
                }
              }
            }
          },
        }
      ).then((stripeResult) => {
        if(stripeResult.error) {
          this.handlePaymentErr(stripeResult.error.message);
        } else {
          if (stripeResult.paymentIntent.status == 'succeeded') {
            this.ticketsService.checkPayment(this.communityCode, this.ticketPurchaseUuid)
              .subscribe((response) => {
                if (response.result == 'ok') {
                  this.paymentStarted = false;
                  this.ref.close();
                  Swal.fire({
                    title: this.translate.instant('TICKETS.BuySuccess'),
                    html: this.translate.instant('TICKETS.MailConfirmationSent') + `
                    <br>
                    <a target='_blank' href='/${this.communityCode}/ticket-print/${response.ticketPurchaseUuid}?print=true' style='background-color: ${this.corpColor} !important' class='btn download-btn'>
                      <i class="fa-solid fa-file-arrow-down"></i> ${this.translate.instant('TICKETS.Download')}
                    </a>`,
                    icon: 'success',
                    confirmButtonText: this.translate.instant('Accept'),
                  })
                }
              })
          }
        }
      })
      .catch((err) => this.handlePaymentErr(err.message))
    } catch (err) {
      this.handlePaymentErr(err.message);
    }
  }

  private handlePaymentErr(msg: string) {
    this.paymentStarted = false;
    console.error('ERROR AL PAGAR: ', msg);
    Swal.fire({
      title: this.translate.instant('TICKETS.TryAgain'),
      text: this.translate.instant('TICKETS.ErrorOnPay'),
      icon: 'warning',
      confirmButtonText: this.translate.instant('TICKETS.RepeatAgain')
    })
  }

  private purchase() {
    let details = this.ticket.prices
      .filter(x => x.qty && x.qty != 0)
      .map(x => ({
        quantity: x.qty,
        ticketPriceId: x.id
      }));

    if(this.totalPrice == 0){
      this.isFreePayment = true;
    }
    console.log(this.customFieldsForm);
    this.ticketsService.purchase(this.communityCode, this.ticket.id, this.userData, details , this.customFieldsForm).subscribe(async (res) => {
      console.log('stripe', res);
      if (res.result == 'stripe') {
        console.log('stripe', res)
        this.stripe = await loadStripe(STRIPE_PUBKEY);
        this.paymentLoaded = true;
        this.clientSecret = res.stripeData.paymentIntent;
        this.ticketPurchaseUuid = res.ticketPurchaseUuid;

        const appearance = {
          theme: 'flat',
          variables: { colorPrimary: this.corpColor }
        };

        this.stripeElements = this.stripe.elements({
          'clientSecret': this.clientSecret,
          locale: this.lang,
          appearance
        });

      } else if (res.result == 'ok') {
        this.ref.close();

        this.isPurchaseLoading = false;

        Swal.fire({
          title: this.translate.instant('TICKETS.BuySuccess'),
          html: this.translate.instant('TICKETS.MailConfirmationSent') + `
          <br>
          <a target='_blank' href='/${this.communityCode}/tickets/print/${res.ticketPurchaseUuid}?print=true' style='background-color: ${this.corpColor} !important' class='btn download-btn'>
            <i class="fa-solid fa-file-arrow-down"></i> ${this.translate.instant('TICKETS.Download')}
          </a>`,
          icon: 'success',
          confirmButtonText: this.translate.instant('Accept'),
        })

      } else {

      }
    });
  }

  private async init() {
    this.communityService.Get()
    .subscribe((result) => {
      this.communityId = result.id;
      this.corpColor = result.color1;
      this.communityCode = result.code;
      this.isCustom = this.customCommunities.includes(this.communityCode);
      this.isLoading = false;
      setTimeout(() => this.initSwiper(), 0)

      this.languageService.getCurrentLang()
        .subscribe((language) => this.lang = language)
    });
  }

  private initSwiper() {
    this.swiperLoaded = true;
    setTimeout(() => this.swiper = document.querySelector('swiper-container'), 50)
  }
}
