<div class="loading-screen" *ngIf="isLoading">
  <app-spinner></app-spinner>
</div>

<ng-container *ngIf="!isLoading">
  <!-- Header -->
  <app-header *ngIf="!isIframe" [community]="community" [style]="'--corp-color-1: ' + community?.color1"></app-header>

  <!-- Body -->
  <div class="body-wrapper"
    [class.iframe]="isIframe"
    [style]="'--corp-color-1: ' + community?.color1"
  >
    <router-outlet></router-outlet>
  <!-- <div class="content-wrapper max-width-wrapper"        
       [class.iframe]="isIframe"       
       [style]="'--corp-color-1: ' + community?.color1">
    <div class="main-content">
    </div>  

  </div> -->
  </div>

  <!-- Footer -->
  <app-footer *ngIf="!isIframe" [community]="community" [style]="'--corp-color-1: ' + community?.color1"></app-footer>
</ng-container>
