import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CommunityDTO } from 'src/app/DTO/CommunityDTO';
import { ApiService } from 'src/app/services/api.service';
import { CommunityService } from 'src/app/services/community/community.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent {

  isIframe = false;
  public community?: CommunityDTO;
  isLoading = true;  
  public hideColumn: boolean;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private actRoute: ActivatedRoute,
    private communityService: CommunityService) {    
      this.router.events.forEach((event) => {
        if (event instanceof NavigationEnd) {
          const segments = event.url.split('/');          
          const isEvents = segments.includes('events');          
          this.hideColumn = isEvents && segments.length > 3;          
        }        
      });
  }

  ngOnInit(): void {
    let c = this.actRoute.snapshot.params['community'];
    
    this.actRoute.queryParams.subscribe((params) => {
      this.isIframe = params['iframe'] !== undefined;
    })
    
    this.apiService.getCommunityByCode(c).subscribe(res => {
      if (res) {        
        this.community = res;        
        this.communityService.Set(this.community);   
        if(this.actRoute.children.length == 0) {
          this.getFirstPage();
        } else {
          this.isLoading = false;
        }          
      } else {
        this.router.navigateByUrl('/error');
      }
    });
  }

  getFirstPage() {
    this.communityService.setMenuTabs()
      .then((res) => {                
        if(res.length > 0) {
          this.router.navigateByUrl(`/${this.community.code}/${res[0].route}`)
        } else {
          this.router.navigateByUrl(`/${this.community.code}/events`);
        }
        this.isLoading = false;
      })
  }
}
