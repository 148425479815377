import { Component, OnInit, Optional } from '@angular/core';
import { MetadataService } from './services/metadata.service';
import { ApiService } from "./services/api.service";
import { CommunityDTO } from "./DTO/CommunityDTO";
import { ActivatedRoute } from "@angular/router";
import { Fancybox } from '@fancyapps/ui/dist/fancybox/fancybox.esm.js';
import { CommunityService } from './services/community/community.service';
import { LanguageService } from './services/language.service';
import { register } from 'swiper/element/bundle';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  show = false;
  communityName = "";
  communityCode: string;
  community: CommunityDTO;
  selected: number = 1;

  menuTabs: any[] = [];

  public currentLang: string;
  public langs: any[] = [];

  constructor(
    @Optional() private metadataService: MetadataService,
    private apiService: ApiService,
    private activeRoute: ActivatedRoute,
    private communityService: CommunityService,
    private languageService: LanguageService
  ) {

    this.languageService.init();

    Fancybox.bind("[data-fancybox]", {
      Thumbs: {
        type: "classic",
      },
    });
  
    // Swiper init
    register();
  }

  ngOnInit(): void {
    this.apiService.getNavChangeEmitter()
      .subscribe(item => {
        this.show = true;
        this.communityCode = item;        
      });

    if (this.metadataService) {
      /*this.metadataService.updateMetadata({
        title: 'Default Page',
        description: 'There is some content on Default Page'
      });*/
    }

    this.languageService.getCurrentLang().subscribe((res) => {
      this.currentLang = res;
    });
    this.languageService.getAvailableLanguages().subscribe((lngs) => {
      this.langs = lngs;
    });

    this.getMenuTabs();
  }

  getCommunity() {
    this.apiService.getCommunityByCode(this.communityCode).subscribe(responseData => {
      this.community = responseData;        
    })
  }

  menuChange(element: number) {
    this.show = false;
    switch (element) {
      case 1:
        this.selected = 1;
        break;
      case 2:
        this.selected = 2;
        break;
      case 3:
        this.selected = 3;
        break;
      case 4:
        this.selected = 4;
        break;
      default:
        this.selected = 1;
        break;
    }
  }

  getMenuTabs() {    
    this.communityService.setMenuTabs()
      .then((tabs) => {              
        if(tabs) {          
          this.menuTabs = tabs;              
        }
      });
  }

  changeLang(lang) {    
    this.languageService.setLanguage(lang, true);
  }
}
