import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ShareLinkRoutingModule } from './share-link-routing.module';
import { ShareLinkComponent } from './share-link.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { SpinnerModule } from 'src/app/components/spinner/spinner.module';


@NgModule({
  declarations: [
    ShareLinkComponent
  ],
  imports: [
    CommonModule,
    ShareLinkRoutingModule,
    FontAwesomeModule,
    TranslateModule,
    SpinnerModule
  ],
  exports: [
    ShareLinkComponent
  ]
})
export class ShareLinkModule { }
