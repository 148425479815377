<div style="min-height: 60vh;">
  <div class="row pb-3 " >
    <div class="col-sm-6">
      <h2 class="text-xl font-weight-bolder">Inscripcions</h2>
    </div>
  </div>

  <ng-container *ngIf="!inscriptions && !isLoading && !filtersLoading">
    <app-empty-state icon="fa fa-calendar" [text]="'No hay inscripciones'"></app-empty-state>
  </ng-container>

  <ng-container *ngIf="!isLoading || !filtersLoading">
    <p-multiSelect
      styleClass="filter-btn"
      [loading]="categoriesOptions.length === 0"
      [options]="categoriesOptions"
      display="chip"
      optionLabel="name"
      optionValue="id"
      [(ngModel)]="categoriesSelected"
      (onChange)="filterInscriptions(true)"
      [placeholder]="'INCIDENTS.IncidentType_Select' | translate">
    </p-multiSelect>
  </ng-container>

  <ng-container *ngIf="isLoading">
    <div class="row" *ngIf="filtersLoading">
      <div class="col-md-6 col-12">
        <p-skeleton styleClass="w-100" borderRadius="22px" height="45px"></p-skeleton>
      </div>
    </div>
    <p-skeleton styleClass="w-25 mt-4"></p-skeleton>
    <div class="row mt-2">
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3 ">
        <p-skeleton styleClass="w-100" height="250px"></p-skeleton>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3">
        <p-skeleton styleClass="w-100" height="250px"></p-skeleton>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3">
        <p-skeleton styleClass="w-100" height="250px"></p-skeleton>
      </div>
    </div>
    <p-skeleton styleClass="w-25 mt-5"></p-skeleton>
    <div class="row mt-2">
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3">
        <p-skeleton styleClass="w-100" height="250px"></p-skeleton>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3">
        <p-skeleton styleClass="w-100" height="250px"></p-skeleton>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3">
        <p-skeleton styleClass="w-100" height="250px"></p-skeleton>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="inscriptions && !isLoading">
    <!-- PROXIMS -->
    <div>
      <!--<h3 class="text-sm mb-1">{{ 'EVENTS.Next' | translate }}</h3>-->
      <ng-container>
        <div class="row">
          <app-inscription-card
            class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-3"
            *ngFor="let card of inscriptions"
            [inscription]="card"
            [routerLink]="[card.id]" queryParamsHandling="merge">
          </app-inscription-card>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <div class="paginator-wrapper mt-4">
    <p-paginator
      styleClass="custom-paginator"
      #paginatorElement
      (onPageChange)="pageChange($event)"
      [first]="page"
      [rows]="rows"
      [totalRecords]="collectionSize"/>
  </div>
</div>
