import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

export enum ResultMessageType {
  Error = 'E',
  Success = 'S'
}

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ResultMessageComponent implements OnInit {

  title: string;
  message: string;
  type: ResultMessageType;

  MessageType = ResultMessageType;

  constructor(private location: LocationStrategy,
              private activeRoute: ActivatedRoute,
              private translate: TranslateService) { }

  ngOnInit(): void {          
    this.setErrorMessage();
  }
  
  setErrorMessage() {
    const state: any = this.location.getState();
    
    this.translate.get(['ERROR.IncorrectURL', 'Oops'])
      .subscribe((t) => {
        this.title = state?.title ?? t['Oops'];
        this.message = state?.message ?? t['ERROR.IncorrectURL'];
        this.type = state?.type ?? this.MessageType.Error
      });
  }

}
