import { TicketDTO } from "./TicketDTO";
import { TicketPurchaseDetailDTO } from "./TicketPurchaseDetailDTO";

export class TicketPurchaseDTO {
    uuid: string;
    ticket: TicketDTO;


    userFullName: string;
    userMail: string;
    userDni: string;

    totalQuantity: number;

    price: number;
    total: number;

    qrData: string;

    details: TicketPurchaseDetailDTO[];

    isValidated: boolean;
    validationCount: number;
    validationDate: string;

    createdAt: string;
}
