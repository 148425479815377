import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, input, type OnInit } from '@angular/core';
import { tick } from '@angular/core/testing';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { EsdevenimentDTO } from 'src/app/DTO/EsdevenimentDTO';
import { TicketPriceDTO } from 'src/app/DTO/TicketPriceDTO';
import { CommunityService } from 'src/app/services/community/community.service';
import {InscriptionsDTO} from "../../DTO/InscriptionsDTO";

@Component({
  selector: 'app-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss'],
})
export class EventCardComponent implements OnInit {

  @Input() event: EsdevenimentDTO;
  @Input() isCustomCommunity: boolean;

  public prices: TicketPriceDTO[];
  public isFree: boolean;
  public communityCode: string = '';
  public dateFrom: Date;
  public dateTo: Date = null;
  public sameDay: boolean;
  public duration: number;
  public defaultImg: string;

  constructor(private translate: TranslateService,
              private communityService: CommunityService,
              private actRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.defaultImg = this.communityService.defaultImg;
    this.communityCode = this.actRoute.snapshot.params['community'];
    const jsDateFrom = new Date(this.event.dateFrom);
    this.dateFrom = jsDateFrom;
    if(this.event.dateTo != null) {
      const jsDateTo = new Date(this.event.dateTo);
      this.dateTo = jsDateTo;

      const dateFromCheck = new Date(jsDateFrom.getFullYear(), jsDateFrom.getMonth(), jsDateFrom.getDate());
      const dateToCheck = new Date(jsDateTo.getFullYear(), jsDateTo.getMonth(), jsDateTo.getDate());

      this.sameDay = dateFromCheck.toLocaleDateString() == dateToCheck.toLocaleDateString();

      if(!this.sameDay) {
        this.duration = this.getEventDuration();
      }
    }
    this.prices = this.event.ticketPrices;
    this.isFree = this.prices.every(x => x.totalWithFee == 0);
  }

  private getEventDuration(): number {
    const dateFrom = DateTime.fromJSDate(this.dateFrom);
    const dateTo = DateTime.fromJSDate(this.dateTo);

    const diff = dateTo.diff(dateFrom).as('days');

    return Math.trunc(diff);
  }
}
