import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CommunityDTO } from 'src/app/DTO/CommunityDTO';
import { ApiService } from 'src/app/services/api.service';
import { CommunityService } from 'src/app/services/community/community.service';

@Component({
  selector: 'app-column-layout',
  templateUrl: './column-layout.component.html',
  styleUrls: ['./column-layout.component.scss']
})
export class ColumnLayoutComponent {

  isIframe = false;
  public community?: CommunityDTO;
  isLoading = true;  
  public hideColumn: boolean;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private actRoute: ActivatedRoute) { }

  ngOnInit(): void {
    let c = this.actRoute.snapshot.params['community'];
    
    this.actRoute.queryParams.subscribe((params) => {
      this.isIframe = params['iframe'] !== undefined;
    });
    
    this.apiService.getCommunityByCode(c).subscribe(res => {
      if (res) {        
        this.community = res;                        
      } else {
        this.router.navigateByUrl('/error');
      }
    });
  }
}
