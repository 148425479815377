import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommunityDTO } from 'src/app/DTO/CommunityDTO';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-contacte',
  templateUrl: './contacte.component.html',
  styleUrls: ['./contacte.component.scss']
})
export class ContacteComponent implements OnInit {

  communityCode: string;
  community?: CommunityDTO;

  constructor(
    private actRoute: ActivatedRoute,
    private apiService: ApiService
  ) {
    this.communityCode = this.actRoute.snapshot.params['community'];
  }

  ngOnInit(): void {
    this.apiService.getCommunityByCode(this.communityCode)
    .subscribe(responseData => {
      this.community = responseData;
    })
  }
  

}
