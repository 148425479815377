import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
 
@Injectable()
export class LanguageHttpInterceptor implements HttpInterceptor {
 
    constructor(public translate: TranslateService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let lang = this.translate.currentLang;

        if (lang) {
            request = request.clone({ headers: request.headers.set('Accept-Language', lang) });
        }

        return next.handle(request);
    }
 
}