import {Component, HostListener, OnInit} from '@angular/core';
import {ApiService} from "../../../services/api.service";
import {CustomFieldDTO} from "../../../DTO/CustomFieldDTO";
import {DialogService, DynamicDialogConfig} from "primeng/dynamicdialog";
import {RecurrenceDTO} from "../../../DTO/RecurrenceDTO";
import {BonificationDTO} from "../../../DTO/BonificationDTO";
import {InscriptionSentDTO} from "../../../DTO/InscriptionSentDTO";
import Swal from "sweetalert2";
import {TranslateService} from "@ngx-translate/core";
import {InscriptionsDTO} from "../../../DTO/InscriptionsDTO";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import { InscriptionsConstants } from '../constants';
import {CommunityService} from "../../../services/community/community.service";
import {ScreenSizeService} from "../../../services/screen-size.service";

@Component({
  selector: 'app-inscription-form',
  templateUrl: './inscription-form.component.html',
  styleUrl: './inscription-form.component.scss'
})
export class InscriptionFormComponent implements OnInit{

  public isLoading = true;

  public communityCode: string;

  public formGroup: FormGroup;

  public inscription: InscriptionsDTO;
  public inscriptionSent: InscriptionSentDTO;
  public customFields : CustomFieldDTO[];

  public recurrences: RecurrenceDTO[];
  public bonifications: BonificationDTO[];

  public files: any[] = [];
  public showTheDocument = false;
  public corpColor: string;
  public increment: number = 0;

  public finalPrice: number;
  public finalPriceWithDiscounts: number;
  public finalRecurrence: string;
  public isMobile: boolean;
  @HostListener('window:resize') onresize() {
    this.isMobile = this.screenSizeService.isScreenSmall();
  }
  public RecurrencesTypes = InscriptionsConstants.RecurrencesTypes;

  constructor(
    private apiService: ApiService,
    private _formBuilder: FormBuilder,
    private dialogConfig: DynamicDialogConfig,
    private translate: TranslateService,
    private dialogService: DialogService,
    private communityService: CommunityService,
    private screenSizeService: ScreenSizeService
  ) {
  }

  ngOnInit(): void {
    this.formGroup = this._formBuilder.group({});
    this.isMobile = this.screenSizeService.isScreenSmall();
    this.communityService.Get()
      .subscribe((result) => {
        this.corpColor = result.color1;
      });

    this.inscription = this.dialogConfig.data.inscription;
    this.communityCode = this.dialogConfig.data.route.snapshot.params['community'];
    this.inscriptionSent = new InscriptionSentDTO();
    console.log(this.inscription);
    this.inscriptionSent.finalRecurrence = this.inscription.defaultRecurrence;

    this.finalPrice = this.inscription.basePrice;
    this.finalPriceWithDiscounts = this.finalPrice;
    this.finalRecurrence = this.inscription.defaultRecurrence;

    if (this.inscription){
      this.apiService.getCustomFields(this.communityCode,this.inscription.id).subscribe(responseData => {
        this.customFields = responseData.filter(x=> x.publicPermissions == 'rw');
        this.isLoading = false;

        for (const customField of this.customFields) {
          this.formGroup.addControl(customField.id.toString(), this._formBuilder.control(null));
        }
      });

      this.apiService.getBonificationsInscription(this.communityCode,this.inscription.id).subscribe(responseData => {
        this.bonifications = responseData;
      });

      this.apiService.getRecurrenceInscription(this.communityCode,this.inscription.id).subscribe(responseData => {
        let recurrences = [{
          id: null,
          type: this.inscription.defaultRecurrence,
          discount: 0
        } as RecurrenceDTO];

        this.recurrences = recurrences.concat(responseData);
      });
    }
  }

  save(){

    if(this.formGroup.valid){

    this.inscriptionSent.customFields = this.customFields;
    this.inscriptionSent.inscriptionId = this.inscription.id;

    Swal.fire({
      reverseButtons: true,
      title: this.translate.instant('AreYouSure'),
      html: this.translate.instant('INSCRIPTIONS.Alert.ConfirmSend'),
      icon: 'warning',
      position: "top",
      showCancelButton: true,
      confirmButtonColor: '#ef5350',
      confirmButtonText: this.translate.instant('Continue'),
      cancelButtonText: this.translate.instant('Cancel'),
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: (result) => {
        return this.apiService.sendInscription(this.communityCode , this.inscriptionSent)
          .toPromise()
          .then((result) => {
            this.dialogService.dialogComponentRefMap.forEach(dialog => {
              dialog.destroy();
            });
            Swal.fire({
              title: this.translate.instant('GENERIC.good'),
              position: "top",
              icon: 'success',
              html: this.inscription.confirmMessage,
              confirmButtonText: this.translate.instant('Accept')
            });
          })
          .catch(error => {
            Swal.fire({
              title: this.translate.instant('GENERIC.bad'),
              icon: 'error',
              position: "top",
              html: 'No s\'ha pogut enviar',
              confirmButtonText: this.translate.instant('Accept')

            });
          });
      }
    }).then((result) => {

    });
    }else{
      Swal.fire({
        position: "top" ,
        title: this.translate.instant('GENERIC.bad'),
        icon: 'warning',
        text: this.translate.instant('CheckFormFields'),
        confirmButtonText: this.translate.instant('Continue')

      });
    }
  }

  saveIncrement(object: any) {

    if(object.delete){
      this.increment = this.increment - object.price;
    }else{
      this.increment = this.increment + object.price;
    }

    this.recalPrice();
  }

  recalPrice() {

      this.finalPrice = this.inscription.basePrice + this.increment;
      this.finalPriceWithDiscounts =  this.finalPrice;

      let bonification = this.bonifications.find(x=> x.id == this.inscriptionSent.bonificationId);

      if (bonification){
        this.finalPriceWithDiscounts *= (1 - bonification.discount / 100);
        this.showTheDocument = bonification.documentRequired;
      }
      else
      {
        this.showTheDocument = false;
      }

      let recurrence = this.recurrences.find(x => x.type == this.inscriptionSent.finalRecurrence);

      if ( recurrence){
        this.finalPriceWithDiscounts *= (1 - recurrence.discount / 100);
        this.finalRecurrence = recurrence.type;
        this.adjustPriceByRecurrence();
      } else {
        this.finalRecurrence = this.inscription.defaultRecurrence;
      }
  }

  adjustPriceByRecurrence(){
    let recurrence = this.recurrences.find(x => x.type == this.inscriptionSent.finalRecurrence);
    if ( recurrence){
      let originMulti = this.getMultiplierForRecurrenceType(this.inscription.defaultRecurrence);
      let finalMulti = this.getMultiplierForRecurrenceType(recurrence.type);

      this.finalPrice = this.finalPrice * (1 / originMulti) * finalMulti;
      this.finalPriceWithDiscounts = this.finalPriceWithDiscounts * (1 / originMulti) * finalMulti;
    }
  }

  getMultiplierForRecurrenceType(recurrenceType: string){
    switch (recurrenceType) {
      case this.RecurrencesTypes.Monthly:
        return 1/12;
      case this.RecurrencesTypes.Quarterly:
        return 1/4;
      case this.RecurrencesTypes.Yearly:
        return 1;
      case this.RecurrencesTypes.OneTime:
        return 1;
    }
  }
}
