import {Component, Input, OnInit} from '@angular/core';
import {CustomFieldDTO} from "../../DTO/CustomFieldDTO";
import {CustomFieldValueDTO} from "../../DTO/CustomFieldValueDTO";

@Component({
  selector: 'app-custom-field-display',
  templateUrl: './custom-field-display.component.html',
  styleUrl: './custom-field-display.component.scss'
})
export class CustomFieldDisplayComponent implements OnInit{
  @Input() fieldvalue: CustomFieldValueDTO;
  constructor() {
  }

  ngOnInit(): void {
  }
}
