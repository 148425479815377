import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ImageUrlPipe } from 'src/app/pipes/image-url.pipe';
import { LocalizedDatePipe } from './localized-date.pipe';


@NgModule({
    imports: [
        CommonModule,                        
    ],
    exports: [
        ImageUrlPipe,
        LocalizedDatePipe
    ],
    declarations: [
        ImageUrlPipe,
        LocalizedDatePipe
    ]
})
export class PipesModule { }
