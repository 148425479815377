import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, type OnInit } from '@angular/core';
import { TicketPurchaseDTO } from 'src/app/DTO/tickets/TicketPurchaseDTO';
import { TicketsService } from '../../services/tickets.service';
import { CommunityService } from 'src/app/services/community/community.service';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-ticket-qr',
  templateUrl: './ticket-qr.component.html',
  styleUrl: './ticket-qr.component.scss',  
})
export class TicketQrComponent implements OnInit, OnDestroy {

  public model = new TicketPurchaseDTO();
  public now = new Date();
  public isLoading = true;
  public colorCorp: string;

  private communityName: string;
  private uuid: string;
  private interval: any;

  constructor(private ticketService: TicketsService,      
              private actRoute: ActivatedRoute,
              private api: ApiService        
  ) {}

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    if (this.interval) clearInterval(this.interval);
  }

  private init() {
    this.communityName = this.actRoute.snapshot.params['community'];
    const uuid = this.actRoute.snapshot.params['uuid'];

    const printParam = this.actRoute.snapshot.queryParams['print'];
    console.log('params', this.actRoute.snapshot.queryParams)

    this.api.getCommunityByCode(this.communityName).subscribe(responseData => {
      this.colorCorp = responseData.color1;

      this.ticketService
        .getMyTicketAnonymous(this.communityName, uuid)
        .subscribe((ticket) => {          
          this.model = ticket;
          this.now = moment().toDate();
          this.isLoading = false;
          if (printParam && !this.isLoading) {
            this.print();
          }
        })
    })
  }

  private print() {
    setTimeout(() => {
      window.print();
    }, 0)
  }
}
