<h2 class="mb-3 text-l font-weight-bolder">Empreses col·laboradores</h2>

<div class="banner mb-4">
    <img
        src="https://wetown-test.storage.googleapis.com/imgs/gironahostaleria/announcements/d3a95861-8b7e-4779-82e4-db4d77ec73e0.png" />
    <img
        src="https://wetown-test.storage.googleapis.com/imgs/gironahostaleria/announcements/2aa339e0-6eb7-49bf-b014-1d648a9dc34c.png" />
    <img
        src="https://wetown-test.storage.googleapis.com/imgs/gironahostaleria/announcements/617def26-60ed-4313-a510-92ba6f86b84c.png" />
    <img src="https://wetown-test.storage.googleapis.com/imgs/gironahostaleria/announcements/60068c37-76f3-4cec-bad0-b3e9979fc732.png"/>
    <img src="https://wetown-test.storage.googleapis.com/imgs/gironahostaleria/announcements/5964f650-56c6-4107-b198-b1bed1a973a0.png"/>
</div>
