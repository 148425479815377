import {Component, Input, OnInit} from '@angular/core';
import {InscriptionsDTO} from "../../DTO/InscriptionsDTO";
import {TranslateService} from "@ngx-translate/core";
import {CommunityService} from "../../services/community/community.service";
import {ActivatedRoute} from "@angular/router";
@Component({
  selector: 'app-inscription-card',
  templateUrl: './inscription-card.component.html',
  styleUrl: './inscription-card.component.scss'
})
export class InscriptionCardComponent implements  OnInit{

  @Input() inscription: InscriptionsDTO;

  public communityCode: string = '';
  public dateFrom: Date;
  public dateTo: Date = null;
  public defaultImg: string;

  constructor(
    private translate: TranslateService,
    private communityService: CommunityService,
    private actRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.dateFrom = new Date(this.inscription.dateOpen);
    this.dateTo = new Date(this.inscription.dateClose);
    this.defaultImg = this.communityService.defaultImg;
    this.communityCode = this.actRoute.snapshot.params['community'];
  }
}
