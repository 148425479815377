import { AfterViewInit, Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { StripeElements, loadStripe, StripeCardElement } from '@stripe/stripe-js';
import { STRIPE_PUBKEY } from 'src/environments/environment';

@Component({
  selector: 'app-stripe-payment',
  templateUrl: './stripe-payment.component.html',
  styleUrls: ['./stripe-payment.component.scss'],  
})
export class StripePaymentComponent implements OnInit {

  @ViewChild('cardInfo') cardInfo: ElementRef;
  
  @Input() communityId: number;
  @Input() clientSecret: string;
  @Input() lang: string;
  @Input() colorCorp: string;
  @Input() elements: any;  

  @Output() emitPayment = new EventEmitter();  

  public paymentElement: any;
  
  private stripe: any;
    
  constructor() {}

  ngOnInit(): void {     
    if(this.clientSecret) {
      this.initStripe(); 
    } 
  }  

  private async onCardChange({ complete, empty }) {    
    if(complete && !empty) {
      this.emitPayment.emit(true)        
    }
  }

  private async initStripe() {    
    this.stripe = await loadStripe(STRIPE_PUBKEY);

    const options = {
      layout: {
        type: 'tabs',
        defaultCollapsed: false,
      },
      fields: {
        billingDetails: {
          address: {
            country: 'never'
          } 
        }
      }
    };

    this.lang = (this.lang == 'ca' || this.lang == 'es') ? 'es' : this.lang;

    this.paymentElement = this.elements.create('payment', options);
    this.paymentElement.mount(this.cardInfo.nativeElement);
    this.paymentElement.addEventListener('change', this.onCardChange.bind(this));
  }
}
