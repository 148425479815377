<ng-container *ngIf="!isLoading">
    <div [style]="'--color-corp-1:' + colorCorp +'; background: ' + colorCorp" class="ticket-wrapper">
        <div class="ticket">
            <ng-container *ngIf="model">
                <div class="title">{{ model.ticket.eventName || model.ticket.title}}</div>
                <div class="subtitle">
                    {{model.createdAt | date:'d/MM/yyyy HH:mm'}}
                </div>
                <hr>
                <div class="user">
                    <h2>{{model.userFullName}}</h2>
                    <div>{{model.userMail}}</div>
                </div>
                <hr>
                <div class="detail">
                    <table>
                        <tr *ngFor="let d of model.details">
                            <td>{{d.ticketPriceName}}</td>
                            <td>{{d.quantity}}</td>
                        </tr>
                        <tr class="total">
                            <td><b>{{ 'TICKETS.TOTAL_TICKETS' | translate }}</b></td>
                            <td><b>{{model.totalQuantity}}</b></td>
                        </tr>
                        <tr class="total" *ngIf="model.total == 0">
                            <td colspan="2" class="ion-text-center">
                                <b>{{'Free' | translate}}</b>
                            </td>
                        </tr>
                        <tr class="total" *ngIf="model.total > 0">
                            <td colspan="2" class="ion-text-center">
                                <b>{{model.total | currency: 'EUR'}}</b>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="rip">
                    <div class="circleLeft"></div>
                    <div class="ripLine"></div>
                    <div class="circleRight"></div>
                </div>
                <div class="qr-wrapper">
                    <div class="text">{{ 'TICKETS.SHOW_QR_AT_ENTRANCE' | translate }}</div>
                    <qrcode [qrdata]="model.qrData" [width]="128" [margin]="0" errorCorrectionLevel="M" elementType="img">
                    </qrcode>
                </div>
                <div class="subdetail">
                    <div class="code"></div>
                    <div class="time">{{now | date:'d/MM/yyyy HH:mm:ss'}}</div>
                </div>
                <div class="shadow"></div>
            </ng-container>
        </div>
    </div>
</ng-container>