
<div class="modal-body loading text-center" *ngIf="!customFields || isLoading">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div style="padding-bottom: 150px" class="form-inscription" *ngIf="customFields && !isLoading">
  <form [formGroup]="formGroup" #form="ngForm">
    <div class="row">
      <div class="col-12 col-md-6" *ngFor="let field of customFields; let i = index;">
        <app-custom-field-input
          *ngIf="field"
          [customField]="field"
          (changeThePrice)="saveIncrement($event)"
          formControlName="{{field.id.toString()}}"
          [class.error]="formGroup.get(field.id.toString())?.invalid && form.submitted"
        ></app-custom-field-input>
        
        <div class="mb-3 text-danger">
          <div *ngIf="formGroup.get(field.id.toString())?.hasError('required') && form.submitted">
            {{ 'RequiredField' | translate }}
          </div>
  
          <div *ngIf="formGroup.get(field.id.toString())?.hasError('format') && form.submitted">
            {{ 'IncorrectFormat' | translate }}
          </div>
        </div>

      </div>


      <div *ngIf="recurrences.length > 1" class="col-12 col-md-6 d-flex flex-column mb-3 mb-md-0">
        <div>{{ 'INSCRIPTIONS.PaymentType' | translate }}</div>
        <p-dropdown [options]="recurrences"
                    [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="inscriptionSent.finalRecurrence"
                    optionValue="type"
                    [filter]="true"
                    (ngModelChange)="recalPrice()">
          <ng-template let-selected pTemplate="selectedItem">
            <div class="flex align-items-center gap-2">
              <div>{{ ('INSCRIPTIONS.PAYMENT_RECURRENCES.' + selected.type) | translate }} <b *ngIf="selected.discount">-{{ selected.discount | number:'1.0-2'}}%</b></div>
            </div>
          </ng-template>
          <ng-template let-x pTemplate="item">
            <div class="flex align-items-center gap-2">
              <div>{{ ('INSCRIPTIONS.PAYMENT_RECURRENCES.' + x.type) | translate }} <b *ngIf="x.discount">-{{ x.discount | number:'1.0-2'}}%</b></div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
      <div *ngIf="bonifications.length > 0" class="col-12 col-md-6 d-flex flex-column">
        <div>{{ 'INSCRIPTIONS.Bonus' | translate }}</div>

        <p-dropdown [options]="bonifications"
                    [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="inscriptionSent.bonificationId"
                    optionValue="id"
                    [filter]="true"
                    [showClear]="true"
                    [placeholder]="'INSCRIPTIONS.BonusPlaceholder' | translate"
                    (ngModelChange)="recalPrice()">
          <ng-template let-selected pTemplate="selectedItem">
            <div class="flex align-items-center gap-2">
              <div>{{ selected.name }} <b>-{{ selected.discount | number:'1.0-2'}}%</b></div>
            </div>
          </ng-template>
          <ng-template let-x pTemplate="item">
            <div class="flex align-items-center gap-2">
              <div>{{ x.name }}  <b>-{{ x.discount | number:'1.0-2'}}%</b></div>
            </div>
          </ng-template>
        </p-dropdown>

        <label class=" col-form-label font-w-600 mb-e mt-3" *ngIf="showTheDocument">
          {{ 'INSCRIPTIONS.BonusDocument' | translate }}
        </label>

        <div *ngIf="showTheDocument">
          <app-file-uploader
            category="inscriptionSent"
            [communityNameInput]="communityCode"
            [maxFiles]="5"
            [type]="1"
            [(files)]="this.inscriptionSent.files"
          ></app-file-uploader>

        </div>
      </div>
    </div>

    <div *ngIf="!isMobile" class="mt-3 d-flex justify-content-between button-fixed-bottom p-3 align-items-center">
      <div *ngIf="inscription.enrollmentFee">
        <div  class="mt-2 d-flex align-items-center" *ngIf="inscription.enrollmentFee">
          <div class="spacing">
            <img src="assets/icons/money.svg" >
          </div>
          <b>{{ 'INSCRIPTIONS.RegistrationFee' | translate }}:</b>
        </div>
        <div class="d-flex mb-3" *ngIf="inscription.enrollmentFee">
          <div class="spacing"></div>
          {{ inscription.enrollmentFee | currency: 'EUR':'symbol':'1.0-2' }}
        </div>
      </div>
      <div>
        <div  class="mt-2 d-flex align-items-center">
          <div class="spacing">
            <img src="assets/icons/euro.svg" >
          </div>
          <b>{{ 'Price' | translate }}</b>
        </div>
        <div class="d-flex mb-3">
          <div class="spacing"></div>
          <div class="d-flex flex-column">
            <div *ngIf="finalPriceWithDiscounts != finalPrice" class="price-original-wrapper me-2">
              <span class="price"> {{finalPrice | currency: 'EUR':'symbol':'1.0-2' }}</span>
              <span *ngIf="finalRecurrence != RecurrencesTypes.OneTime" class="recurrence"> / {{ ('INSCRIPTIONS.PAYMENT_RECURRENCES_AUX.' + finalRecurrence) | translate | lowercase }}</span>
            </div>

            <div>
              <span class="price"> {{finalPriceWithDiscounts | currency: 'EUR':'symbol':'1.0-2' }}</span>
              <span *ngIf="finalRecurrence != RecurrencesTypes.OneTime" class="recurrence"> / {{ ('INSCRIPTIONS.PAYMENT_RECURRENCES_AUX.' + finalRecurrence) | translate | lowercase }}</span>
            </div>
          </div>
        </div>
      </div>
        <button class=" buy-btn rounded" style="max-width: 200px" [style]="'--corp-color-1:' +corpColor+ ';'" [class.opacity]="formGroup.invalid" (click)="save()">  {{ 'INSCRIPTIONS.Enroll' | translate }}</button>
    </div>
    <div class="rgpd mt-3">
      <small [innerHTML]="inscription.rgpd"></small>
    </div>
    <div class="mt-2 fixed-btn p-4" *ngIf="isMobile">
      <div class="d-flex mb-2">



        <div *ngIf="inscription.enrollmentFee" class="me-2">
          <div  class="mt-2 d-flex align-items-center">
            <div class="spacing">
              <img src="assets/icons/money.svg" >
            </div>
            {{ inscription.enrollmentFee | currency: 'EUR':'symbol':'1.0-2' }}
          </div>

        </div>

        <div>
          <div  class="mt-2 d-flex align-items-center">
            <div class="spacing">
              <img src="assets/icons/euro.svg" >
            </div>
            <span>{{ inscription.basePrice | currency: 'EUR':'symbol':'1.0-2' }}</span>
            <span *ngIf="inscription.defaultRecurrence != 'OneTime'" class="recurrence"> / {{
              ('INSCRIPTIONS.PAYMENT_RECURRENCES.' + inscription.defaultRecurrence) | translate | lowercase}}</span>
          </div>
        </div>

      </div>
      <button class=" buy-btn rounded" [style]="'--corp-color-1:' +corpColor+ ';'" [class.opacity]="formGroup.invalid" (click)="save()">  {{ 'INSCRIPTIONS.Enroll' | translate }}</button>

    </div>
  </form>
</div>


