import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NewDTO } from 'src/app/DTO/NewDTO';
import { ApiService } from 'src/app/services/api.service';

import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { MetadataService } from 'src/app/services/metadata.service';
import { TranslateService } from '@ngx-translate/core';
import { Functions } from 'src/app/helpers/Functions';
import { CommunityService } from 'src/app/services/community/community.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsListComponent implements OnInit {

  communityName = "";
  news: NewDTO[] = [];

  public title: string;
  
  public paginator: boolean = false;
  public page = 1;
  public collectionSize = 50;
  private pageSize = 10;

  newsLoaded: boolean = false;

  faArrowRight = faArrowRight;
  faArrowLeft = faArrowLeft;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private actRoute: ActivatedRoute,
    private metadataService: MetadataService,
    private translate: TranslateService,
    private communityService: CommunityService
    ) {
      
  }

  ngOnInit(): void {
    this.title = this.translate.instant('News');

    this.communityName = this.actRoute.snapshot.params['community'];

    this.getCommunity();
    
    this.communityService.GetModulesDict().subscribe((res) => {
      if (res && res["NEWS"]){
        this.title = res["NEWS"].name;
      }
    });
    
    this.apiService.countNews(this.communityName).subscribe((res) => {
      if(res){
        this.collectionSize = res;
      }
    });
    this.getNews();
  }

  getNews(){
    this.apiService.getNewsPaginated(this.pageSize, (this.page-1) * this.pageSize, this.communityName).subscribe(responseData => {
      this.news = responseData;
      this.newsLoaded = true;      

      if(this.page != 1 || this.news.length >= this.pageSize){
        this.paginator = true;
      } else {
        this.paginator = false;
      }

      if(this.news.length < this.pageSize){
        this.collectionSize = (this.pageSize * (this.page-1)) + this.news.length;
      }
    });
  }

  pageChange(){
    this.newsLoaded = false;
    /*window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });*/
    this.getNews();
  }
  
  getCommunity() {
    this.apiService.getCommunityByCode(this.communityName).subscribe(res => {
      
      if(res) {
        this.metadataService.updateMetadata({
          title: Functions.decode(res.name) + " - " + this.title,
          description: this.translate.instant('NEWS.NewsFrom') + Functions.decode(res.name)
        });   
      }
    });
  }

}
