<ng-container *ngIf="isLoading">
    <div class="ticket-container" [style]="'--corp-color-1: ' + corpColor+ ';'">
        <div class="ticket mb-3">
            <div class="row justify-content-between mt-1 mb-1">
                <div class="price-info col-5 col-md-5">
                    <p-skeleton styleClass="w-50 mt-1"></p-skeleton>
                    <p-skeleton styleClass="w-25 mt-2"></p-skeleton>
                </div>
                <div class="col-md-3 col-5">
                    <p-skeleton styleClass="w-100" height="50px"></p-skeleton>                    
                </div>
            </div>
            <div class="row justify-content-between mt-1 mb-1">
                <div class="price-info col-5 col-md-5">
                    <p-skeleton styleClass="w-50 mt-1"></p-skeleton>
                    <p-skeleton styleClass="w-25 mt-2"></p-skeleton>
                </div>
                <div class="col-md-3 col-5">
                    <p-skeleton styleClass="w-100" height="50px"></p-skeleton>                    
                </div>
            </div>
        </div>
        <div class="total-box">
            <p-skeleton styleClass="w-50"></p-skeleton>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="!isLoading">
    <div class="ticket-container" [style]="'--corp-color-1: ' + corpColor + ';'">        
        <div class="ticket mb-3">
            <div class="buy-section row mt-1 mb-1" *ngFor="let price of ticket.prices">
                <div class="price-info col-5 col-md-6">
                    <span>{{ price.name }}</span>
                    <span *ngIf="price.total != 0" class="price-text">{{ price.total | currency:'€' }}</span>
                    <span *ngIf="price.total == 0" class="price-text">{{ 'Free' | translate }}</span>
                </div>
                <div class="col-7 col-md-6">
                    <div class="text-end display-right">
                        <p-inputNumber  
                            styleClass="input-width"
                            class="input-width"                     
                            (ngModelChange)="onChangeQty($event, price)"
                            [(ngModel)]="price.qty"
                            [showButtons]="true"
                            buttonLayout="horizontal"
                            inputId="horizontal"
                            spinnerMode="horizontal"
                            [step]="1"
                            incrementButtonIcon="pi pi-plus"
                            decrementButtonIcon="pi pi-minus"
                            [min]="0"
                            [max]="ticket.maxByUser">
                        </p-inputNumber>
                    </div>
                </div>                  
            </div>      
        </div>
        <div class="total-price">
            <span>
                {{ 'Total' | translate }}:
            </span>
            <strong>{{ total | currency:'€' }}</strong>
        </div>      
    </div>
</ng-container>
