import { Component, Input } from '@angular/core';
import { CommunityDTO } from 'src/app/DTO/CommunityDTO';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  
  @Input () community?: CommunityDTO;

}
