import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { environment } from 'src/environments/environment';
import {Observable, Subscription} from 'rxjs';

import { DatePipe } from '@angular/common';
import { ImageUrlPipe } from 'src/app/pipes/image-url.pipe';
import { Alertes } from 'src/app/DTO/ModalData';
import { LeafletService } from 'src/app/services/leaflet/leaflet.service';
import { Map, MapOptions } from 'leaflet';

@Component({
  selector: 'app-mapbox',
  templateUrl: './mapbox.component.html',
  styleUrls: ['./mapbox.component.scss'],
})
export class MapboxComponent implements OnInit, OnDestroy {

  @Input() lat: number;
  @Input() lng: number;
  @Input() zoom: number;
  @Input () centerlng: number;
  @Input () centerlat: number;
  @Input () arrayAlertes: Alertes [];
  @Input() moveTo: Observable<number[]>;
  @Input() reload: Observable<Alertes[]>;
  @Input() pushNew: Observable<Alertes>;
  @Output() openModal = new EventEmitter();
  @Input () scrollWheelZoom = false;

  public markervarsList: any[] = [];
  private eventsSubscription: Subscription;
  private reloadSubscription: Subscription;
  private pushNewSubscription: Subscription;

  //public isLoading = false;

  map: Map;
  //mapOptions: MapOptions;

  newMarkerWave: any;

  @HostListener('document:click' , ['$event'])
  public onClick(event) {
    if (event.target.classList.contains('popup-button')) {
      this.openModal.emit(event.target.getAttribute('data-id'));
    }
  }

  constructor(
    public _imagePipe: ImageUrlPipe,    
    private datePipe: DatePipe,
    private leafletService: LeafletService
  ) {
   }

  ngOnInit() {
    if (this.leafletService.L) {
      // Leaflet is loaded - load the map!
      setTimeout(() => {
        this.initMap();
      }, 0);

      if (this.moveTo) {
        this.eventsSubscription = this.moveTo.subscribe((e) => this.focusMarker(e[2], e[0], e[1]));
      }
      if (this.reload) {
        this.reloadSubscription = this.reload.subscribe((e) => this.reloadMap(e));
      }
      if (this.pushNew) {
        this.pushNewSubscription = this.pushNew.subscribe((e) => {
          this.addMarker(e.locationLat, e.locationLng, e.id, e, true);
          this.focusMarker(e.id, e.locationLat, e.locationLng);
        });
      }

    } else {
      // When the server renders it, it'll show this.
    }
  }

  ngOnDestroy() {
    if (this.eventsSubscription) {
      this.eventsSubscription.unsubscribe();
    }
    if (this.reloadSubscription) {
      this.reloadSubscription.unsubscribe();
    }
    if (this.pushNewSubscription) {
      this.pushNewSubscription.unsubscribe();
    }
  }

  private initMap() {
    this.zoom = this.zoom ?? 16;

    let mapOptions = {
      attributionControl: false,
      center: this.leafletService.L.latLng(this.centerlat, this.centerlng),
      zoom: this.zoom,
      dragging: true,
      scrollWheelZoom: this.scrollWheelZoom,
      zoomControl: false,
      layers: [
        this.leafletService.L.tileLayer(
          `https://api.mapbox.com/styles/v1/dfargas/ckle1xk064nir17nu31n2dd0c/tiles/{z}/{x}/{y}?access_token=${environment.MAPBOX_TOKEN}`,
          {
            maxZoom: 20,
          }),
      ],
    } as MapOptions;

    this.map = this.leafletService.L.map('map', mapOptions);
    this.onMapReady(this.map);
  }

  onMapReady(map: Map) {
    console.log('map', map)
    this.map = map;

    setTimeout(() => {
      map.invalidateSize();
    }, 0);

    map.addControl(this.leafletService.L.control.zoom({ position: 'bottomright' }));

    if (this.arrayAlertes) {
      for (const alert of this.arrayAlertes) {
        this.addMarker(alert.locationLat , alert.locationLng  , alert.id, alert);
      }
    } else if (this.lat && this.lng) {
      this.addMarker(this.lat , this.lng);
    }
  }

  private addMarker(lat: number , lng: number  , alertId?: number , alert?: Alertes, isNew = false) {
    if (alert) {
      const markerVar = this.leafletService.L.marker([lat, lng], {
        icon: this.leafletService.L.icon({
          iconSize: [38, 38],
          iconAnchor: [19, 38],
          iconUrl: 'assets/icons/markers/marker_' + alert.statusId + '.svg',
          shadowUrl: 'assets/icons/markers/marker_shadow.png',
          shadowAnchor: [13, 40],
          //className: isNew ? 'blinking' : ''
        }),
        opacity: alert.statusId !== 5 ? 0.7 : 1
      });

      this.markervarsList.push(markerVar);

      if (alertId) {
        markerVar['alertId'] = alertId;
        this.popUp(markerVar, alert);
        this.map.addLayer(markerVar);
      }

      if (isNew) {
        const svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        svgElement.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
        svgElement.setAttribute('viewBox', '0 0 100 100');
        svgElement.innerHTML = `
          <circle cx="50%" cy="50%" r="0" fill-opacity="0" stroke="#2196f3" stroke-width="1px" stroke-opacity="1">
            <animate attributeName="r" from="0" to="15" dur="2s" repeatCount="indefinite" />
            <animate attributeName="stroke-opacity" from="1" to="0" dur="2s" repeatCount="indefinite"></animate>
          </circle>`;

        if (this.newMarkerWave) {
          this.map.removeLayer(this.newMarkerWave);
        }

        const svgElementBounds = [ this.leafletService.L.latLng([lat - 0.002, lng - 0.002]), this.leafletService.L.latLng([lat + 0.002, lng + 0.002])];
        this.newMarkerWave = this.leafletService.L.svgOverlay(svgElement, this.leafletService.L.latLngBounds(svgElementBounds));
        this.map.addLayer(this.newMarkerWave);
      }
    } else {

      const markerVar = this.leafletService.L.marker([lat, lng], {
        icon: this.leafletService.L.icon({
          iconSize: [38, 38],
          iconAnchor: [19, 38],
          iconUrl: 'assets/icons/markers/marker_5.svg',
          shadowUrl: 'assets/icons/markers/marker_shadow.png',
          shadowAnchor: [13, 40],
        })
      });

      this.map.addLayer(markerVar);
    }
  }

  private popUp(markerVar: any , alert?: Alertes) {    

      markerVar.bindPopup(`
      <div class="pop-up-box">
        <div>
          ${alert.categoryInternalName != null ? alert.categoryInternalName : alert.categoryName}
          <!-- <time>${this.datePipe.transform(alert.createdAt, 'dd/MM/yyyy')}</time>-->
        </div>
        <div class="title">${alert.name}</div>
        <div class="img-background-popover mt-2" style="background-image: url(${this._imagePipe.transform(alert.images[0])})"></div>
        <div>
          <button data-id="${alert.id}" class="popup-button btn bg-green-200 w-100 mt-2 text-xxs font-w-600">Veure més</button>
        </div>
      </div>
      `).openPopup();
    
  }

  public focusMarker(alertId: number, lat: number, lng: number) {
    this.flyTo(lat, lng);

    if (this.markervarsList) {
      const v =  this.markervarsList.find(x => x.alertId === alertId);
      if (v) {
        v.openPopup();
      }
    }
  }

  public flyTo(lat: number, lng: number) {
    this.map.flyTo(this.leafletService.L.latLng(lat , lng), 17 );
  }

  public reloadMap (arrayAlertes: Alertes[]) {
    if (this.map) {
      this.cleanMarkerVar();
      if (arrayAlertes) {
        for (const alert of arrayAlertes) {
          this.addMarker(alert.locationLat, alert.locationLng, alert.id, alert);
        }
      }
    }
  }
  public cleanMarkerVar() {
    for (const mar of this.markervarsList) {
      mar.remove();
    }
    this.markervarsList = [];
  }
}
