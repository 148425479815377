import { Component, OnInit } from '@angular/core';
import { BUCKET_NAME } from 'src/environments/environment';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {

  public BUCKET_NAME  = BUCKET_NAME;
  constructor() { }

  ngOnInit(): void {
  }

}
