// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: 'dev',
  tag: 'dev',
  production: false,
  MAPBOX_TOKEN: 'pk.eyJ1IjoiZGZhcmdhcyIsImEiOiJjbHJoczVjY2MwMjdzMm1xdm1sam1kNXBrIn0.VuTCyTGElm0kN33ORihZRg',
  storageURL: 'https://storage.googleapis.com/wetown-test',
};

export const STRIPE_PUBKEY = 'pk_test_51MskAiHLcumNZ77OD7GIpsqmRNjQ69FgNKS4jV8QtL3M8blJ5BSneDS15qRpQT7DDzrBlslsNPth53bxiZYvkJfV00sqZYnl47';
export const API_URL = 'https://api.dev.wetown.app';
export const BUCKET_NAME = 'wetown-test';


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
