<div class="footer" [ngStyle]="{ 'background-color' : community?.color1 }">
    <div class="max-width-wrapper">
        <div class="row">
            <div class="col-md-4 mobile-center">
                <div class="logo mb-3">
                    <ng-container *ngIf="community && community.code != 'gironahostaleria'">
                        <img [src]="(community.logoNegative ?? community.image) | imageUrl" />
                        <!--<span style="margin-left: 1em;">{{community.name}}</span>-->
                    </ng-container>
                    <ng-container *ngIf="community && community.code == 'gironahostaleria'">
                        <img class="gironahostaleria" src="assets/gironahostaleria/logo-blanc-vertical.svg" />
                    </ng-container>
                </div>
            </div>
            <div class="col-md-4 mobile-center">
                <div class="colabs" *ngIf="community && community.code == 'gironahostaleria'">
                    <div class="title">COL·LABORADORS:</div>
                    <div>
                        <img src="assets/gironahostaleria/aj_girona.png" />
                        <img src="assets/gironahostaleria/generalitat.png" />
                    </div>
                </div>
            </div>
            <div class="col-md-4 mobile-center">
                <div class="contact">
                    <!--<div class="title">CONTACTE:</div>-->
                    <div *ngIf="community?.mail">
                        <a href="mailto:{{community.mail}}">{{community.mail}}</a>
                    </div>
                    <div *ngIf="community?.phone">
                        {{community?.phone}}
                    </div>
                    <div *ngIf="community?.locationString">
                        {{community?.locationString}}
                    </div>
                    <div class="social-icons" *ngIf="community && community.code == 'gironahostaleria'">
                        <a href="https://www.facebook.com/AHTRG"><i class="fa fa-facebook"></i></a>
                        <a href="https://twitter.com/Gi_Hostaleria"><i class="fa fa-twitter"></i></a>
                        <a href="https://www.instagram.com/gironahostaleria/"><i class="fa fa-instagram"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
