<div style="min-height: 60vh;">
<div class="row pb-3 ">
  <div class="col-sm-6">
      <h2 *ngIf="!isCustom" class="text-xl font-weight-bolder">{{ title }}</h2>
      <h2 *ngIf="isCustom" class="text-xl font-weight-bolder">{{ 'EVENTS.Entrances' | translate }}</h2>
    </div>
  </div>

  <ng-container *ngIf="typesLoaded && !isCustom">
    <div class="flex-row">
      <div class="" *ngIf="typesOptions.length != 0">
          <p-multiSelect [styleClass]="'filter-btn'" [options]="typesOptions" [(ngModel)]="selectedTypes"
            [placeholder]="'Category' | translate" optionLabel="name" optionValue="id" display="chip"
            (ngModelChange)="getFilterData($event, 'category')" [showToggleAll]="true" [showClear]="true">
          </p-multiSelect>
      </div>
      <div id="target" #targetEl>

        <p-dropdown styleClass="filter-btn date-dropdown" #dateDropdown  [placeholder]="'EVENTS.SelectDate' | translate" [options]="dateOptions"
          (onClear)="getDateOption(null)"
          [(ngModel)]="dateOptionSelected" optionLabel="label" [showClear]="true" >
          <ng-template pTemplate="selectedItem">
            <div class="date-option"  *ngIf="dateOptionSelected && !isOverlay">
              <i class="calendar-icon"></i>
              {{ dateOptionSelected.label }}
            </div>
            <div *ngIf="dateOptionSelected && isOverlay">
              <div class="date-option" *ngIf="dateOptionSelected.value == 4">
                <i class="calendar-icon"></i>
                {{ dateRanges[0] | date:'dd/MM/yyyy' }}
              </div>
              <div class="date-option" *ngIf="dateOptionSelected.value == 5">
                <i class="calendar-icon"></i>
                {{ dateRanges[0] | date:'dd/MM/yyyy' }}
                <i *ngIf="dateRanges[1]" class="fa fa-long-arrow-right"></i>
                {{ dateRanges[1] | date:'dd/MM/yyyy' }}
              </div>
            </div>
          </ng-template>
          <ng-template let-option pTemplate="item">
            <div class="flex align-items-center gap-2">
              <div (click)="getDateOption(option)">{{ option.label }}</div>
            </div>
          </ng-template>
        </p-dropdown>

        <!-- OVERLAYS -->
        <p-overlayPanel #singleDay (onShow)="onRangeShow($event)" (onHide)="onSingleHide($event)">
          <p-calendar
            class="size-s"
            styleClass="size-s"
            [inline]="true"
            [(ngModel)]="singleDateSelected"
            [dateFormat]="'dd/MM/yyyy'"
            (onSelect)="getSingleDate($event)">
          </p-calendar>
        </p-overlayPanel>
        <p-overlayPanel #rangeDates (onShow)="onRangeShow($event)" (onHide)="onRangeHide($event)">
          <p-calendar
            class="size-s"
            [inline]="true"
            [(ngModel)]="rangedDateSelected"
            [selectionMode]="'range'"
            [dateFormat]="'dd/MM/yyyy'"
            (ngModelChange)="getRangeDates($event)">
          </p-calendar>
        </p-overlayPanel>
        <!-- ------ -->

      </div>
      <div class="">
        <p-dropdown
          styleClass="filter-btn price-dropdown"
          [options]="priceOptions"
          [(ngModel)]="selectedPrice"
          optionLabel="label"
          [showClear]="true"
          (ngModelChange)="getSelectedPrice($event)"
          [placeholder]="'EVENTS.SelectPrice' | translate">
          <ng-template pTemplate="selectedItem">
            <div *ngIf="selectedPrice">
              <i class="euro-icon"></i>
              {{ selectedPrice.label }}
            </div>
          </ng-template>
          <ng-template let-option pTemplate="item">
            <div class="flex align-items-center gap-2">
              <div>{{ option.label }}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="allEvents && allEvents.length == 0 && esdevenimentsLoaded">
    <app-empty-state icon="fa fa-calendar" [text]="'EVENTS.No_Events' | translate"></app-empty-state>
  </ng-container>

  <ng-container *ngIf="!esdevenimentsLoaded">
    <div class="row" *ngIf="!typesLoaded">
      <div class="col-md-6 col-12">
          <p-skeleton styleClass="w-100" borderRadius="22px" height="45px"></p-skeleton>
      </div>
    </div>
    <p-skeleton styleClass="w-25 mt-4"></p-skeleton>
    <div class="row mt-2">
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3 ">
        <p-skeleton styleClass="w-100" height="250px"></p-skeleton>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3">
        <p-skeleton styleClass="w-100" height="250px"></p-skeleton>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3">
        <p-skeleton styleClass="w-100" height="250px"></p-skeleton>
      </div>
    </div>
    <p-skeleton styleClass="w-25 mt-5"></p-skeleton>
    <div class="row mt-2">
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3">
        <p-skeleton styleClass="w-100" height="250px"></p-skeleton>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3">
        <p-skeleton styleClass="w-100" height="250px"></p-skeleton>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3">
        <p-skeleton styleClass="w-100" height="250px"></p-skeleton>
      </div>
    </div>
  </ng-container>

  <!-- DESTACATS -->
  <ng-container *ngIf="featuredEvents.length > 0 && esdevenimentsLoaded && !isCustom">
      <h3 class="text-sm mb-3">{{ 'EVENTS.Featured' | translate }}</h3>
  </ng-container>

  <swiper-container pagination="true" init="false" class="mb-3" [class.d-none]="!esdevenimentsLoaded">
    <swiper-slide *ngFor="let e of featuredEvents">
      <app-event-card
        [isCustomCommunity]="isCustom"
        [routerLink]="[e.slug? e.slug : e.id]"
        queryParamsHandling="merge"
        [event]="e">
      </app-event-card>
    </swiper-slide>
  </swiper-container>


  <ng-container *ngIf="allEvents && allEvents.length > 0 && esdevenimentsLoaded">
      <!-- PROXIMS -->
      <div *ngIf="nextEvents.length > 0 && esdevenimentsLoaded">
        <h3 *ngIf="!isCustom" class="text-sm mb-1">{{ 'EVENTS.Next' | translate }}</h3>
        <ng-container *ngIf="nextEvents && nextEvents.length != 0">
          <div class="row">
            <app-event-card
              [isCustomCommunity]="isCustom"
              class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-3"
              *ngFor="let card of nextEvents"
              [event]="card"
              [routerLink]="[card.slug? card.slug : card.id]" queryParamsHandling="merge">
            </app-event-card>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <div class="paginator-wrapper mt-4">
      <p-paginator
        styleClass="custom-paginator"
        #paginatorElement
        (onPageChange)="pageChange($event)"
        [first]="page"
        [rows]="rows"
        [totalRecords]="collectionSize"/>
    </div>
</div>
