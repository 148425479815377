import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { Observable, of, timer, EMPTY } from 'rxjs';
import { expand, skip } from 'rxjs/operators';

export const MINUTE = 60;
export const HOUR = MINUTE * 60;
export const DAY = HOUR * 24;
export const WEEK = DAY * 7;
export const MONTH = DAY * 30;
export const YEAR = DAY * 365;

@Injectable()
export class RelativeTimeClock {
  tick(then: DateTime): Observable<any> {
    return of(0)
      .pipe(
        expand(() => {
          const seconds = Math.abs(then.diffNow().as('seconds'));

          const period =
            seconds < MINUTE
              ? 1000
              : seconds < HOUR
                ? 1000 * MINUTE
                : seconds < DAY
                  ? 1000 * HOUR
                  : 0;

          return period ? timer(period) : EMPTY;
        }),
        skip(1)
      );
  }
}