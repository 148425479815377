import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';

export enum DateIsBetweenResult {
  Before = -1,
  Between = 0,
  After = 1
}

@Injectable({
  providedIn: 'root'
})
export class Helpers {

  /**
   *  Return (-1) if the date/now is before start date, (0) if is between, and (1) if is after end date.
   * @param startDateStr From (If null, startDate infinite)
   * @param endDateStr To (If null, endDate infinite)
   * @param dateStr Optional (If null, date == NOW)
   * @returns (-1) | (0) | (1)
   */
  public static CheckIfDateIsBetween(startDateStr: string, endDateStr: string, dateStr: string = null) : DateIsBetweenResult{
    let now:DateTime<true> | DateTime<false> = DateTime.now();

    if(dateStr){
      now = DateTime.fromISO(dateStr);                
    }

    if(startDateStr){
      let start = DateTime.fromISO(startDateStr);
      if(now <= start){
        // Scheduled
        return -1;
      }
    }

    if(endDateStr){
      let end = DateTime.fromISO(endDateStr);
      if(now >= end){
        // Finished
        return 1;
      }
    }

    return 0;
  }

  /**
   *  Return dicitonary from list of objects
   * @param list List of objects
   * @param keyProp Property name for the keys of the dictionary
   * @returns object (dictionary)
   */
    public static ConvertListToDict(list: any[], keyProp: string): any{
      let dict = {};
      if(list){
        list.forEach(el => {
          dict[el[keyProp]] = el;
        });
      }
  
      return dict;
    }

  /**
   *  Return dicitonary from list of objects
   * @param list List of objects
   * @param idGen Property for the keys of the dictionary
   * @returns object (dictionary)
   */
    public static ListToDict<T>(list: T[], idGen: (arg: T) => string): { [key: string]: T } {
      const dict: { [key: string]: T } = {}
    
      list.forEach((element) => {
        const dictKey = idGen(element)
        dict[dictKey] = element
      })
    
      return dict
    }
    
  /**
   * Return GetOnlyTheHostFromUrl
   * @param url Url string
   * @returns string
   */
  public static GetOnlyTheHostFromUrl(url: string) : string {
    if(!url) return url;
    
    const urlObj = new URL(url);
    urlObj.protocol;  // "http:"
    urlObj.hostname;  // "aaa.bbb.ccc.com"
    return urlObj.protocol + '//' + urlObj.hostname;
  }

}
