import { Injectable } from '@angular/core';
import { colord, extend } from "colord";
import mixPlugin from "colord/plugins/mix";
import { CommunityDTO } from 'src/app/DTO/CommunityDTO';

extend([mixPlugin]);

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  
  public customLogo: boolean;
  
  private _appName: string;

  constructor(
  ) { }

  public get appName() {
    return this._appName;
  }

  public setTheme(c: CommunityDTO){
    this._appName = c?.appTitle;
    this.customLogo = c?.appTitle != null;
  }

  public static getThemeCssVariablesByCommunity(c: CommunityDTO){
    let ret = '';
    const isCustomCommunity = c.customAppId != null;

    if(c){
     
      if(c.color1 && c.color2){ 
        ret += `
        --corp-color-gradient-from: ${c.color1}; 
        --corp-color-gradient-to: ${c.color2};
        
        --corp-color-1: ${c.color1}; 
        --corp-color-2: ${colord(c.color1).mix(c.color2, 0.25).toHex()};
        --corp-color-3: ${colord(c.color1).mix(c.color2, 0.5).toHex()};
        --corp-color-4: ${colord(c.color1).mix(c.color2, 0.75).toHex()};
        --corp-color-5: ${c.color2};
  
        --corp-background: linear-gradient(90deg, var(--corp-color-gradient));
      
        `;
      }
    }

    return ret;
  }

  private static shade(c: string){
    return colord(c).darken(0.07).desaturate(0.27).toHex();
  }

  private static tint(c: string){
    return colord(c).lighten(0.05).toHex();
  }
  
}