import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapboxComponent } from './mapbox.component';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { ImageUrlPipe } from 'src/app/pipes/image-url.pipe';
import { LeafletService } from 'src/app/services/leaflet/leaflet.service';

@NgModule({
  declarations: [
    MapboxComponent,
  ],
  imports: [
    CommonModule,
    PipesModule,    
  ],
  exports: [
    MapboxComponent
  ],
  providers: [
    DatePipe,
    ImageUrlPipe,
    LeafletService
  ]
})
export class MapboxComponentModule {}