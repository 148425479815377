import { DatePipe } from '@angular/common';
import { Pipe, type PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localizedDate',  
  pure: false
})
export class LocalizedDatePipe implements PipeTransform {

  constructor(private translateService: TranslateService,              
  ) {
  }

  transform(value: any, pattern: string = 'mediumDate'): any {
    if (!value) return null;
    const datePipe: DatePipe = new DatePipe(this.translateService.currentLang ?? 'es');    
    return datePipe.transform(value, pattern);
  }


}
