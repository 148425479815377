import {CustomFieldValueDTO} from './CustomFieldValueDTO';
import {DateTime} from 'luxon';
import {BonificationDTO} from "./BonificationDTO";
import {RecurrenceDTO} from "./RecurrenceDTO";
import {CustomFieldDTO} from "./CustomFieldDTO";

export class InscriptionSentDTO {
  id: number;
  userId: number;
  customFieldsValues: CustomFieldValueDTO[];
  customFields: CustomFieldDTO[];
  inscriptionId: number;
  createdAt: DateTime;
  nameValue: string;
  surnameValues: string;
  mailValue: string;
  dniValue: string;
  bonificationId : number | null;
  finalRecurrence: string | null;
  bonification: BonificationDTO;
  recurrence: RecurrenceDTO;
  files: any[];

}
