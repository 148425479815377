import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressBarModule} from '@angular/material/progress-bar';
import {MatIconModule} from '@angular/material/icon';

import {FileUploaderComponent} from './file-uploader.component';
import { MatButtonModule } from '@angular/material/button';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  imports: [
    CommonModule,
    MatProgressBarModule,
    MatIconModule,
    MatButtonModule,    
    PipesModule,
    TranslateModule
  ],
  exports: [
    FileUploaderComponent
  ],
  declarations: [
    FileUploaderComponent
  ]
})
export class FileUploaderComponentModule { }
