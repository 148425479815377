<div class="black-sidebar" *ngIf="show" (click)="show = false">
</div>
<div class="sidebar" *ngIf="show" [ngStyle]="{ 'background-color' : community?.color1 }">
  <div class="row m-0 p-0 justify-content-end">
    <div class="col-12 text-end justify-content-end d-flex flex-row mt-3">
      <i class="fa fa-close cursor-pointer" (click)="show = false"></i>
    </div>
    
    <ng-container *ngFor="let menuTab of menuTabs; index as i">
      <div class="col-12 mt-2">
        <div class="menuElement text-m text-end cursor-pointer" (click)="menuChange(i + 1)" [routerLink]="['/' + this.communityCode + '/' + menuTab.route]">
            <span class="cursor-pointer font-weight-bolder pl-4 pb-1" [ngClass]="selected == (i + 1) ? ' selectedItem' : ''" >
                {{ menuTab.name }}
            </span>
        </div>
      </div>    
    </ng-container>

    <div class="col-12">
      <div *ngIf="langs.length > 1" class="lang-selector text-end">
        <ng-container *ngFor="let l of langs; last as isLast">
          <span [class.active]="l.key == currentLang" (click)="changeLang(l.key)" [title]="l.name">{{ l.key | uppercase }}</span> <span *ngIf="!isLast">|</span>
        </ng-container>
      </div>
    </div>
  </div>

</div>
<router-outlet></router-outlet>
