<div class="event-card">
    <div class="image-box">
        <img [src]="event.image ?? defaultImg | imageUrl" >
        <div class="featured-star" *ngIf="event.featured">
            <i class="fa fa-star"></i>
        </div>
        <div class="category" *ngIf="event.categoryId">
            <span>{{ event.categoryName }}</span>
        </div>
        <div class="free-banner" *ngIf="isFree || !event.ticketId">
            <span>{{ 'TICEKTS.FreeEvent' | translate }}</span>
        </div>
    </div>
    <div class="info-box">
        <div class="event-title">
            <span>{{ event.name }}</span>
        </div>
        <div class="data-box" [style]="isCustomCommunity ? 'justify-content: flex-end;' : ''">
            <div class="when" *ngIf="!isCustomCommunity">
                <ng-container *ngIf="sameDay">
                    <span *ngIf="event.allDay">
                        <i class="calendar-icon"></i>
                        {{ event.dateFrom | localizedDate: 'EEE d MMM yyyy'}}
                    </span>
                    <span *ngIf="!event.allDay">
                        <i class="calendar-icon"></i>
                        {{ event.dateFrom | localizedDate: "EEE d MMM yyyy" }}, {{ event.dateFrom | date: 'HH:mm' }} h
                    </span>
                </ng-container>
                <ng-container *ngIf="!sameDay">
                    <span>
                        <i class="calendar-icon"></i>
                        {{
                        event.allDay ?
                        (dateFrom | localizedDate: 'EEE dd MMM')
                        :
                        (dateFrom | localizedDate: 'EEE dd MMM HH:mm') + ' h'
                        }}
                    </span>
                    <ng-container *ngIf="event.dateTo">
                        &nbsp;
                        <span>
                            ({{ duration }} {{ 'Days' | translate }})
                        </span>
                    </ng-container>
                </ng-container>
            </div>
            <div class="buy-btn" >
                <button class="btn-buy">
                    <ng-container *ngIf="event.ticketId">
                        <i class="fa fa-ticket mr-1"></i>
                        <span>{{ (isFree ? 'EVENTS.Register' : 'Buy') | translate }}</span>
                    </ng-container>
                    <ng-container *ngIf="!event.ticketId">
                        <i class="fa fa-search mr-1"></i>
                        <span>{{ 'SeeMore' | translate }}</span>
                    </ng-container>
                </button>
            </div>
        </div>
    </div>
  </div>
