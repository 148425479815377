<ng-container>
    <div class="ticket-container" [style]="'--corp-color-1: ' + corpColor + ';'">
        <h2 class="text-xl mb-1 dialog-title">{{ (fullFree ? 'TICKETS.ReserveYourTickets' : 'TICKETS.BuyYourTickets') |
            translate }}</h2>
        <div class="ticket mb-3">
            <div class="info">
                <h3>{{ ticket.title }}</h3>
                <!-- <span class="ticket-date" *ngIf="!isCustom">
                    {{ dateFrom.toLocaleString({ weekday: 'long', month: 'long', day: '2-digit' }) }}
                </span> -->
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="!swiperLoaded">
    <div class="ticket-container" [style]="'--corp-color-1: ' + corpColor+ ';'">
        <div class="ticket mb-3">
            <div class="row justify-content-between mt-1 mb-1">
                <div class="price-info col-5 col-md-5">
                    <p-skeleton styleClass="w-50 mt-1"></p-skeleton>
                    <p-skeleton styleClass="w-25 mt-2"></p-skeleton>
                </div>
                <div class="col-md-3 col-5">
                    <p-skeleton styleClass="w-100" height="50px"></p-skeleton>
                </div>
            </div>
            <div class="row justify-content-between mt-1 mb-1">
                <div class="price-info col-5 col-md-5">
                    <p-skeleton styleClass="w-50 mt-1"></p-skeleton>
                    <p-skeleton styleClass="w-25 mt-2"></p-skeleton>
                </div>
                <div class="col-md-3 col-5">
                    <p-skeleton styleClass="w-100" height="50px"></p-skeleton>
                </div>
            </div>
        </div>
        <div class="total-box">
            <p-skeleton styleClass="w-50"></p-skeleton>
        </div>
    </div>
</ng-container>

<swiper-container
    *ngIf="swiperLoaded"
    slides-per-view="1"
    loop="false"
    #swiper
    allow-touch-move="false"
    auto-height="true"
    (swiperslidechange)="slideChange($event)">
    <swiper-slide>
        <app-tickets
            [ticket]="ticket"
            (emitNext)="changeStep($event, 'ticket')">
        </app-tickets>
    </swiper-slide>
    <swiper-slide>
        <app-user-data
            [customFields]="customFieldsForm"
            [fullFree]="fullFree"
            [submitAttempt]="submitAttempt"
            (userEmitter)="changeStep($event, 'user')"
            (formValid)="formIsValid = $event"
            >
        </app-user-data>
    </swiper-slide>
    <swiper-slide>
        <ng-container *ngIf="!clientSecret || paymentStarted">
            <div class="spinner-box" >
                <app-spinner
                ></app-spinner>
            </div>
        </ng-container>
        <ng-container *ngIf="clientSecret">
            <app-stripe-payment
                [class]="paymentStarted ? 'd-none' : ''"
                [communityId]="communityId"
                [clientSecret]="clientSecret"
                [lang]="lang"
                [colorCorp]="corpColor"
                [stripe]="stripe"
                [elements]="stripeElements"
                (emitPayment)="changeStep($event, 'payment')">
            </app-stripe-payment>
            <div *ngIf="totalPrice != 0" [class]="paymentStarted ? 'd-none' : 'total-price'">
                <span>{{ 'Total' | translate }}:</span>
                <strong>{{ totalPrice | currency:'€' }}</strong>
            </div>
        </ng-container>

    </swiper-slide>
</swiper-container>

<ng-container>
    <div class="mt-3 buy-box" *ngIf="!isFreePayment">
        <button
            class="btn-buy"
            (click)="prev()"
            *ngIf="currentSlide != 0"
            [style.background-color]="corpColor">
            {{ 'Previous' | translate }}
        </button>
        <button
            type="submit"
            class="btn-buy"
            [class.disabled-btn]="!canContinute"
            [class.disabled-color]="currentSlide == 1 && !formIsValid"
            (click)="next()"
            [style.background-color]="corpColor">
            <span *ngIf="totalPrice == 0 && currentSlide == 1">
                {{ 'End' | translate }}
            </span>
            <span *ngIf="totalPrice != 0 || currentSlide != 1">
                {{ (paymentStep ? 'Pay' : 'Next') | translate }}
            </span>
        </button>
    </div>
</ng-container>

