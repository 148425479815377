import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StorageService {
    
    constructor(
    ) { }

    static Session = class {
        public static get(key: string){
            let val = window?.sessionStorage?.getItem(key);
            return val != null ? JSON.parse(val) : null;
        }
        public static set(key: string, value: any){
            window?.sessionStorage?.setItem(key, JSON.stringify(value));
        }
    }
    static Local = class {
        public static get(key: string){
            let val = window?.localStorage?.getItem(key);
            return val != null ? JSON.parse(val) : null;
        }
        public static set(key: string, value: any){
            window?.localStorage?.setItem(key, JSON.stringify(value));
        }
    }
}